import React from 'react';
import { withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoCollectionApi from '../apis/XanoCollectionApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as WaterMarkFillter from '../custom-files/WaterMarkFillter';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { parentTitle: null };

const WatermarkerBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [markerHeight, setMarkerHeight] = React.useState(
    dimensions.width >= Breakpoints.Desktop ? 150 : 120
  );
  const [markerWidth, setMarkerWidth] = React.useState('');
  const [parentHeight, setParentHeight] = React.useState(0);
  const calculateItems = () => {
    let dum = [];
    if (!parentHeight) return dum;

    for (let i = Math.round(parentHeight / 280); i > 0; i--) dum.push(0);

    console.log('dum length ', dum);
    return dum;
  };

  const floorValue = val => {
    return Math.floor(val);
  };

  const ceilValue = val => {
    return Math.ceil(val);
  };

  const getRotatedHeight = h => {
    // console.log('he ', h)
    // return h ? Math.ceil(h / 0.8192) : 200 // cos(-35deg) = 0.8192

    return Math.ceil((h - 125) / 200);
  };

  return (
    <View
      onLayout={event => {
        try {
          setParentHeight(event?.nativeEvent?.layout?.height);
        } catch (err) {
          console.error(err);
        }
      }}
      {...GlobalStyles.ViewStyles(theme)['watermark conatiner'].props}
      style={StyleSheet.applyWidth(
        StyleSheet.compose(
          GlobalStyles.ViewStyles(theme)['watermark conatiner'].style,
          { overflow: 'hidden' }
        ),
        dimensions.width
      )}
    >
      <XanoCollectionApi.FetchAuthMeGET>
        {({ loading, error, data, refetchAuthMe }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <Utils.CustomCodeErrorBoundary>
              <WaterMarkFillter.WaterMark
                theme={theme}
                parentHeight={parentHeight}
                markerHeight={markerHeight}
                markerWidth={markerWidth}
              >
                <View
                  onLayout={event => {
                    try {
                      setMarkerHeight(event?.nativeEvent?.layout?.height);
                      setMarkerWidth(event?.nativeEvent?.layout?.width);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={[
                    StyleSheet.applyWidth(
                      {
                        alignContent: 'center',
                        alignItems: 'stretch',
                        justifyContent: 'center',
                      },
                      dimensions.width
                    ),
                    { transform: [{ rotate: '-35deg' }] },
                  ]}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { alignContent: 'center', alignItems: 'stretch' },
                      dimensions.width
                    )}
                  >
                    {/* Text 20 */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['watermark text']
                        .props}
                      numberOfLines={2}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['watermark text']
                            .style,
                          { alignSelf: 'center' }
                        ),
                        dimensions.width
                      )}
                      contentContainerStyle={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['watermark text'].style,
                        dimensions.width
                      )}
                      textBreakStrategy={'simple'}
                    >
                      {fetchData?.email}
                    </Text>
                  </View>
                  <Image
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    resizeMode={'contain'}
                    source={imageSource(Images['logorightsmall'])}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        {
                          height: { minWidth: Breakpoints.Desktop, value: 120 },
                          opacity: 0.14,
                          width: 120,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                </View>
              </WaterMarkFillter.WaterMark>
            </Utils.CustomCodeErrorBoundary>
          );
        }}
      </XanoCollectionApi.FetchAuthMeGET>
    </View>
  );
};

export default withTheme(WatermarkerBlock);
